import * as React from 'react'
import Head from 'next/head'

import { ToastContainer } from 'unno-comutils/Dialog'

import 'unno-comutils/style/core.css'
import '../styles/app.scss'

const MyApp = (props: any) => {
    const { Component, pageProps } = props

    return <>
        <Head>
            <title>OBI โบรคเกอร์ประกันภัยรถยนต์ | บจก. ออนไลน์โบรคเกอร์ประกันภัย</title>
            <link rel="icon" href="/favicon.ico"/>
            <link rel="shortcut icon" type="image/x-icon" href="https://files.un-no.net/unno-web/static/favicon/unno_w3.ico"/>
        </Head>
        <div className="layout-container"><Component {...pageProps}/></div>
        <ToastContainer/>

        <div id="fb-root"/>

        <script src="https://files.un-no.net/static/loadCSS.js"/>
        <script dangerouslySetInnerHTML={{
            __html: `
            loadCSS("https://files.un-no.net/static/fa/css/fontawesome.min.css")
            loadCSS("https://files.un-no.net/static/fa/css/light.min.css")
            loadCSS("https://files.un-no.net/static/fa/css/brands.min.css")
            loadCSS("https://files.un-no.net/static/fa/css/solid.min.css")
            `
        }}>
        </script>
    </>
}

export default MyApp
